.ml-error input {
  background: url(https://bucket.mlcdn.com/images/default/error-icon.png) 98% center no-repeat #ffffff !important;
  background-size: 24px 24px !important;
}
.ml-error .label-description {
  color: #ff0000 !important;
}
.ml-error .label-description p {
  color: #ff0000 !important;
}

.ml_message_wrapper {
  position: absolute !important;
  left: -9999px !important;
}

.ml-block-success {
  display: none;
  text-align: center;
}

.ml-success-title {
  margin-top: 150px;
  color: #1f2638;
  font-size: 21px;
  font-weight: bold;
  text-transform: uppercase;
}

.ml-success-title-red {
  margin-top: 110px;
  color: #FF0000;
  font-size: 21px;
  font-weight: bold;
  text-transform: uppercase;
}
