$font-family-default: 'Helvetica', sans-serif;

@function calc-responsive($value1, $width1, $value2, $width2) {
  $x1: $width1;
  $x2: $width2;
  $y1: $value1;
  $y2: $value2;
  $a: ($y2 - $y1) / ($x2 - $x1);
  $b: ($x2 * $y1 - $x1 * $y2) / ($x2 - $x1);
  @return calc(#{$a*100vw} + #{$b*1px});
}

.facebook {
  width: 70%;
  margin-left: 15%;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-right: auto;
  padding-left: 50px;
  font-family: $font-family-default;
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
  border-width: calc-responsive(1, 468, 2, 1920);
  border-style: solid;
  color: #FFF;
  cursor: pointer;
  display: inline-block;
  font-size: calc-responsive(14, 468, 18, 1920);
  text-decoration: none;
  text-transform: uppercase;
  transition: background-color .3s, border-color .3s;
  background-color: #4C69BA;
  border-color: #4C69BA;
  padding: calc-responsive(15, 468, 20, 1920) calc-responsive(20, 468, 25, 1920);
}
