/* GLOBAL STYLES
-------------------------------------------------- */
/* Padding below the footer and lighter body text */

html {
  font-size: 16px;
}


body {
    padding-top: 3rem;
    padding-bottom: 3rem;
    color: #5a5a5a;
    font-family: Azo Sans;
    min-width: 360px;
}

.footer {
    margin-top: 3em;
}

/* NAV  BAR
-------------------------------------------------- */

.navbar {
  background-color: white !important;
  height: 98px;
  box-shadow: 0px 3px 5px 0px #00000026;
}

.nbtn {
    background-color: #328afb;
    border-radius: 15px;
    height: 50px;
    color: #FFFFFF;
    font-size: 0.8rem;
    white-space: nowrap;
    overflow: hidden;
}

.nav-p {
    color:#000000;
    text-align: right;
    font-size: 0.8rem;
    margin-right: 1rem;
}


/* MARKETING CONTENT
-------------------------------------------------- */

.jumbotron {
    background-color: white;
    margin-bottom: 0;
}

.jumbotron-heading {
  text-align: left !important;
  font-size: 1.5rem;
  font-weight: 600;
  color: #333;
}

.jumbotron-subheading {
  text-align: left !important;
  color: #777777;
}

.lead {
    font-size: 1.2rem !important;
    text-align: center;
}

/* Center align the text within the three columns below the carousel */
.press {
    background-color: #F2F1F1;
}

.col-lg-4, .investors .col-lg-4 {
    margin-bottom: 1.5rem;
    text-align: center;
}

.col-lg-4 p {
    margin-right: .75rem;
    margin-left: .75rem;
}

.press h2 {
    color:#898989;
    font-size: 0.8rem;
    font-weight: 600;
}


/* Featurettes
------------------------- */

.featurette {
    margin-bottom: 3em;
}

.featurette-divider {
    margin: 5rem 0; /* Space out the Bootstrap <hr> more */
}

.featurette-heading {
    line-height: 1;
    letter-spacing: -.05rem;
    font-size: 1.5rem;
    text-align: center;
    color: #22276D;
    margin-top: 3rem;
}

.featurette-image-right, .featurette-image-left {
    width: 160px;
    height: 120px;
}



/* Thin out the marketing headings */


/* Investors section */

.investors {
  background-color: #F2F1F1;
}

.investors-company {
    color:#22276D
}

.container p {
    font-size: 0.6rem;
}

/* RESPONSIVE CSS
-------------------------------------------------- */

@media (min-width: 40em) {
    /* Bump up size of carousel content */
    .carousel-caption p {
        margin-bottom: 1.25rem;
        font-size: 1.25rem;
        line-height: 1.4;
    }

    .featurette-heading {
        font-size: 1.5rem;
    }

    .btn {
        width: 152px;
        height: 62px;
        font-size: 1.2rem;
    }

    .nav-p {
        color:#000000;
        text-align: right;
        font-size: 1.3rem;
        margin-right: 5rem;
    }

    .jumbotron-heading {
        font-size: 2.5rem;
        text-align: center;
      }

    .press {
        padding-bottom: 5px;
        margin-bottom: 20px;
    }

    .press h2 {
        font-size: 1.3rem;
    }

    .container p {
        font-size: 1.3rem;
    }
}

@media (min-width: 62em) {
    .featurette-heading {
        margin-top: 5rem;
        font-weight: 600;
        text-align: left;
    }

    .featurette-image-right {
        margin-top: 5rem;
        width: 240px;
        height: 180px;
        float: right;
    }

    .featurette-image-left {
        margin-top: 5rem;
        width: 240px;
        height: 180px;
        float: left;
    }
}

@media (max-width: 992px) {
    .featurette-image-right {
        margin-top: 5rem;
        width: 240px;
        float: right;
    }

    .featurette-image-left {
        margin-top: 5rem;
        width: 240px;
        float: left;
    }
}

@media (max-width: 768px) {
    .featurette-image-right {
        margin-top: 5rem;
        width: 180px;
        float: left;
    }

    .featurette-image-left {
        margin-top: 5rem;
        width: 180px;
        float: right;
    }

    .jumbotron .container {
      margin-left: 0;
      margin-right: 0;
      padding: 0;
    }
}

@media (max-width: 480px) {
    .featurette-heading {
        margin-top: 1.5em;
    }

    .featurette-image-right {
        float: left;
        margin-top: 0.5em;
        width: 140px;
    }

    .featurette-image-left {
        margin-top: 0.5em;
        width: 140px;
        float: right;
    }
}
